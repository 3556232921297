import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[mathText]',
})
export class MathText1Directive implements OnChanges {
  MathJax: any = {};
  
  constructor(public elementRef: ElementRef) {
    this.hostEl = elementRef.nativeElement; //capture the HTML element host
  }

  //Used to bind data: eg: <div [mathText]="raw string">
  @Input('mathText') inputString: string;
  // host element
  private hostEl: HTMLElement;

  //have MathJax parse the host element and render the math
  render() {
    this.MathJax.Hub.Queue(['Typeset', this.MathJax.Hub, this.hostEl]);
  }

  // called when the inputString changes.
  ngOnChanges() {
    //make the input string into the innerText of the host element
    this.hostEl.innerText = this.inputString;
    this.render();
  }
}
