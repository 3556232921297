import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterCheckService {
  public CurrentRouteSource = new BehaviorSubject<string>(null);

  constructor() { }

  setCurrentRoute(route: string) {
    this.CurrentRouteSource.next(route);
  }
  
}

