import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  public scriptElement = window.document.createElement('script');

  constructor() {}

  // Returns a Promise that loads the script
  loadScript1(): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptElement = window.document.createElement('script');
      scriptElement.src =
        'https://cdnjs.cloudflare.com/ajax/libs/countdown/2.6.0/countdown.js';
      scriptElement.onload = () => {
        return resolve('yes');
      };
      scriptElement.onerror = () => {
        reject('no');
      };
      window.document.body.appendChild(scriptElement);
    });
  }

  loadScriptKatex1(): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptElement = window.document.createElement('script');
      scriptElement.src =
        'https://cdn.jsdelivr.net/npm/katex@0.13.11/dist/katex.min.js';
      scriptElement.integrity =
        'sha384-YNHdsYkH6gMx9y3mRkmcJ2mFUjTd0qNQQvY9VYZgQd7DcN7env35GzlmFaZ23JGp';
      scriptElement.crossOrigin = 'anonymous';
      scriptElement.defer = true;
      scriptElement.onload = () => {
        return resolve('yes');
      };
      scriptElement.onerror = () => {
        reject('no');
      };
      window.document.body.appendChild(scriptElement);
    });
  }

  loadScriptKatex2(): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptElement = window.document.createElement('script');
      scriptElement.src =
        'https://cdn.jsdelivr.net/npm/katex@0.13.11/dist/contrib/auto-render.min.js';
      scriptElement.integrity =
        'sha384-vZTG03m+2yp6N6BNi5iM4rW4oIwk5DfcNdFfxkk9ZWpDriOkXX8voJBFrAO7MpVl';
      scriptElement.crossOrigin = 'anonymous';
      scriptElement.defer = true;
      scriptElement.onload = () => {
        return resolve('yes');
      };
      scriptElement.onerror = () => {
        reject('no');
      };
      window.document.body.appendChild(scriptElement);
    });
  }

  loadScriptMathJax(): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptElement = window.document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src =
        'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js';
      scriptElement.async = true;
      // scriptElement.onload = () => {
      //   // console.log('yes');
      //   return resolve('yes');
      // };
      // scriptElement.onerror = () => {
      //   // console.log('no');
      //   reject('no');
      // };
      window.document.body.appendChild(scriptElement);
      // window.document.body.removeChild(scriptElement);
    });
  }

 async loadScriptMathJax2() {
    this.scriptElement = window.document.createElement('script');
    this.scriptElement.type = 'text/javascript';
    this.scriptElement.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js';
    this.scriptElement.async = true;
    window.document.body.appendChild(this.scriptElement);
  }

  removeScriptMathJax2() {
    window.document.body.removeChild(this.scriptElement);
  }

  loadScriptPoly(): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptElement = window.document.createElement('script');
      scriptElement.src = 'https://polyfill.io/v3/polyfill.min.js?features=es6';
      scriptElement.onload = () => {
        return resolve('yes');
      };
      scriptElement.onerror = () => {
        reject('no');
      };
      window.document.body.appendChild(scriptElement);
    });
  }

  loadScript5(): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptElement = window.document.createElement('script');
      scriptElement.src =
        'https://cdnjs.cloudflare.com/ajax/libs/countdown/2.6.0/countdown.js';
      scriptElement.onload = () => {
        return resolve('yes');
      };
      scriptElement.onerror = () => {
        reject('no');
      };
      window.document.body.appendChild(scriptElement);
    });
  }
}
