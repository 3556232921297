import { Pipe, PipeTransform } from '@angular/core';
import { Paragraaf } from '../models/paragraaf.model';

@Pipe({
  name: 'filterOnderwerp'
})
export class FilterOnderwerpPipe implements PipeTransform {

transform(paragrafen: Paragraaf[], hoofdstukId: string) {
  // console.log(paragrafen);
  // console.log(hoofdstukId);
   const filteredItems = paragrafen.filter((v,i,s) => {
     return v.hoofdstukId == hoofdstukId
   })
  // console.log(filteredItems);
return filteredItems;
}

}
