import { SafeHtmlPipe } from './safe-html.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterHoofdstukPipe } from './filter-hoofdstuk.pipe';
import { FilterOnderwerpPipe } from './filter-onderwerp.pipe';
import { FilterParagraafPipe } from './filter-paragraaf.pipe';



@NgModule({
  declarations: [
    SafeHtmlPipe,
    FilterHoofdstukPipe,
    FilterParagraafPipe,
    FilterOnderwerpPipe
  ],
  imports: [

  ],
  exports: [
    SafeHtmlPipe,
    FilterHoofdstukPipe,
    FilterParagraafPipe,
    FilterOnderwerpPipe
  ]
})
export class PipesModule { 
  
}
