// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import * as firebase from 'firebase/compat/app';
import * as firebaseAnalytics from 'firebase/analytics';

///////////////////////App name: Pienter-dev
// export const environment = {
//   production: false,
//   useEmulators: false,
//   firebase: {
//     apiKey: 'AIzaSyDMcu9GZPcUiXSdzf5isHjf2D4jEL4HYOg',
//     authDomain: 'pienter---dev.firebaseapp.com',
//     databaseURL: 'https://pienter---dev-default-rtdb.firebaseio.com',
//     projectId: 'pienter---dev',
//     storageBucket: 'pienter---dev.appspot.com',
//     messagingSenderId: '598760453392',
//     appId: '1:598760453392:web:6f0d9dbf51a3b447aa2195',
//     measurementId: 'G-EEH9PPH7JH',
//   },
//   recaptcha: {
//     siteKey: '6LfvTtQfAAAAAIwhMg_ecDF6dW-fbjiRpy0ZQCjq',
//   },
// };

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC3PbzB5z3V_mYvz9nfzcmcmpXE-2-tJ3w",
    authDomain: "online-learning-one.firebaseapp.com",
    databaseURL: "https://online-learning-one.firebaseio.com",
    projectId: "online-learning-one",
    storageBucket: "online-learning-one.appspot.com",
    messagingSenderId: "570713946237",
    appId: "1:570713946237:web:5de86e80b19844a05d5914",
    measurementId: "G-5XN497H6XZ"
  },
  recaptcha: {
    siteKey: '6LdJeHQeAAAAAKV8cyvom_goqQ6jpp1bd4EurY2D',
  },
};


// import { getAnalytics } from "firebase/analytics";
// const analytics = getAnalytics();

const app = firebase.default.initializeApp(environment.firebase);
const analytics = firebaseAnalytics.initializeAnalytics(app);

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
