import { Pipe, PipeTransform } from '@angular/core';
import { Paragraaf } from '../models/paragraaf.model';

@Pipe({
  name: 'filterHoofdstuk'
})
export class FilterHoofdstukPipe implements PipeTransform {

//   transform(items: any[], callback: (item: any) => boolean): any {
//     if (!items || !callback) {
//         return items;
//     }
//     return items.filter(item => callback(item));
// }

transform(paragrafen: Paragraaf[], hoofdstukId: string) {
  // // console.log(paragrafen);
  // // console.log(hoofdstukId);
   const filteredItems = paragrafen.filter((v,i,s) => {
     return v.hoofdstukId == hoofdstukId
   })
  // // console.log(filteredItems);
return filteredItems;
}

}
