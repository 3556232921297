<!-- <ng-container  *ngIf="!loading"> -->
<!-- <p> -->
<!-- <script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script> -->
<!-- <script id="MathJax-script" async src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"></script> -->
<!-- <math xmlns="http://www.w3.org/1998/Math/MathML"><mroot><mrow><mi>d</mi><mi>w</mi><mi>d</mi></mrow><mrow><mi>d</mi><mi>s</mi><mi>d</mi><mi>w</mi><mi>e</mi></mrow></mroot><mfenced close="}" open="{"><mrow><mi>e</mi><mi>q</mi><mi>w</mi><mi>e</mi><mi>d</mi><mi>w</mi><mi>q</mi><mi>d</mi><mo>∩</mo><mi>e</mi><mi>w</mi><mi>q</mi><mi>e</mi><mi>w</mi><mi>e</mi><mi>q</mi></mrow></mfenced></math> -->
<!-- </p> -->
<!-- {{equation}}  -->



<!-- <div *ngIf="isScriptLoaded"> -->
  <!-- <div>{{timespan}}</div> -->
  <!-- <div>{{equation}}</div>
  <div>$$ \TeX $$ Examples</div>
  < ng-katex-html [html]="equation |safeHtml"></div>
  < ng-katex-html [html]="equation"></div> -->
<!-- </div> -->
<router-outlet></router-outlet>
<!-- </ng-container> -->

<!-- <div class="loading-icon" *ngIf="loading"> <h1>tessssssssss</h1></div> -->