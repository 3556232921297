import { RouterCheckService } from './core/shared/services/router-check.service';
import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './core/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(private auth: AuthService, private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.authenticated()) {
      return true;
    } else {
      // console.log('access denied');
      alert('You are not yet logged in');
      this.router.navigateByUrl('/public-interface/login');
      return false;
    }
  }
}
