import { UserBoekIndeling } from '../../models/user-boek-indeling.model';
import { DatePipe } from '@angular/common';
import { Injectable, TemplateRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestoreCollection,
  AngularFirestoreCollectionGroup,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import firebase from 'firebase/compat/app';
import { Observable, BehaviorSubject, forkJoin, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Boek } from '../../models/boek.model';
import { Hoofdstuk } from '../../models/hoofdstuk.model';
import { Oefening } from '../../models/oefening.model';
import { Onderwerp } from '../../models/onderwerp.model';
import { Paragraaf } from '../../models/paragraaf.model';
import { User } from '../../models/user.model';
import { Vak } from '../../models/vak.model';
import { Klas } from '../../models/klas.model';
import { StudentKlassenOverview } from 'functions/src/models/studentKlassenOverview';
import { NbDialogRef } from '@nebular/theme';
import { Order } from '../../models/order.model';
import {
  where,
  getCountFromServer,
  query,
  collection,
} from 'firebase/firestore';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  userVakken$: Observable<Vak[]>;
  vakkenCollection: AngularFirestoreCollection<Vak>;
  hoofdstukkenCollection: AngularFirestoreCollection<Hoofdstuk>;
  userBoekIndelingenCollection: AngularFirestoreCollection<UserBoekIndeling>;
  onderwerpenCollectionGroup: AngularFirestoreCollectionGroup<Onderwerp>;
  paragrafenCollectionGroup: AngularFirestoreCollectionGroup<Paragraaf>;
  hoofdstukkenCollectionGroup: AngularFirestoreCollectionGroup<Hoofdstuk>;
  oefeningenCollectionGroup: AngularFirestoreCollectionGroup<Oefening>;
  klasenCollectionGroup: AngularFirestoreCollectionGroup<Klas>;
  klassenCollection: AngularFirestoreCollection<Klas>;

  db = firebase.firestore();
  increment = firebase.firestore.FieldValue.increment(1);
  decrement = firebase.firestore.FieldValue.increment(-1);

  boeken$: Observable<Boek[]>;

  private userSource = new BehaviorSubject<User>(null);
  currentUser = this.userSource.asObservable();

  private layoutSource = new BehaviorSubject<string>(null);
  currentLayout = this.layoutSource.asObservable();

  currentDialogRef: NbDialogRef<any>;
  currentStudentKlassenOverview$: BehaviorSubject<StudentKlassenOverview> =
    new BehaviorSubject(null);
  currentStudentKlassen$: BehaviorSubject<Klas[]> = new BehaviorSubject(null);

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private firestore: AngularFirestore,
    private datePipe: DatePipe
  ) {}

  changeUser(user: User) {
    this.userSource.next(user);
  }

  changeLayout(layout: string) {
    this.layoutSource.next(layout);
  }

  getTimeStamp(): string {
    const timeStamp: string = this.datePipe.transform(
      new Date(),
      'yyyy-MM-dd h:mm:ss'
    );
    return timeStamp;
  }

  getBeginPunt(boek: Boek): Observable<Onderwerp> {
    this.onderwerpenCollectionGroup = this.firestore.collectionGroup(
      'onderwerpen',
      (ref) =>
        ref
          .where('boekId', '==', boek.id)
          .where('hoofdstukNummer', '==', '1')
          .where('paragraafNummer', '==', '1')
          .where('onderwerpNummer', '==', '1')
    );
    const onderwerpen$ = this.onderwerpenCollectionGroup.snapshotChanges().pipe(
      map((snaps) => {
        return snaps.map((snap) => {
          const data = snap.payload.doc.data() as Onderwerp;
          data.id = snap.payload.doc.id;
          return data;
        });
      })
    );
    const onderwerp$ = onderwerpen$.pipe(
      map((onderwerpen) => {
        return onderwerpen[0];
      })
    );
    return onderwerp$;
  }

  async updateUserData(user: User) {
    const userRef: AngularFirestoreDocument<User> = this.firestore.doc(
      `users/${user.uid}`
    );
    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      schoolNaam: user.schoolNaam,
      leerjaar: user.leerjaar,
      geboorteDatum: user.geboorteDatum,
      telefoonNummer: user.telefoonNummer,
      voogdTelefoonNummer: user.voogdTelefoonNummer,
    };
    return userRef.set(data, { merge: true });
  }

  async deleteUserKlas(klas: Klas, user: User): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const userDocRef = this.db.doc('users/' + user.uid);
      const index = user.Klassen.findIndex(
        (el) => el.publicKlasId == klas.publicKlasId
      );
      user.Klassen.splice(index, 1);
      userDocRef.update(user);
      return resolve();
    });
  }

  async searchForKlas(publicKlasId: string): Promise<Observable<Klas>> {
    return new Promise(async (resolve, reject) => {
      this.klasenCollectionGroup = this.firestore.collectionGroup(
        'TeacherKlassen',
        (ref) => ref.where('publicKlasId', '==', publicKlasId)
      );

      let klas$ = this.klasenCollectionGroup.snapshotChanges().pipe(
        map((snaps) => {
          return snaps.map((snap) => {
            const data = snap.payload.doc.data() as Klas;
            data.id = snap.payload.doc.id;
            return data;
          });
        }),
        map((el) => el[0])
      );
      return resolve(klas$);
    });
  }

  async addKlasToUserAndTeacher(klas: Klas, user: User): Promise<void> {
    return new Promise(async (resolve, reject) => {
      // console.log(user);
      const userDocRef = this.db.doc('users/' + user.uid);
      if (user.Klassen == undefined) {
        user.Klassen = [];
      }
      let tempUserKlas = {
        id: klas.id,
        publicKlasId: klas.publicKlasId,
        teacherFirstName: klas.teacherFirstName,
        teacherLastName: klas.teacherLastName,
        teacherId: klas.teacherId,
        klasNaam: klas.klasNaam,
        vakNaam: klas.vakNaam,
        vakId: klas.vakId,
        boekNaam: klas.boekNaam,
        boekId: klas.boekId,
      };
      user.Klassen.push(tempUserKlas);
      const paramUser = JSON.parse(JSON.stringify(user));
      const teacherKlasRef = this.db.doc(
        'users/' + klas.teacherId + '/TeacherKlassen/' + klas.id
      );
      const studentKlasRef = this.db.collection(
        'users/' + user.uid + '/TeacherKlassen/' + klas.id
      ).doc;
      const fieldValue = firebase.firestore.FieldValue;
      // const teacherKlas: Klas;
      // teacherKlas.klasStudenten
      // teacherKlassenRef.update({
      //   klasStudenten: fieldValue.arrayUnion(user)
      // })
      const batch = this.db.batch();
      batch.update(userDocRef, paramUser);
      batch.update(teacherKlasRef, {
        klasStudenten: fieldValue.arrayUnion(paramUser),
      });
      batch.commit();
      // userDocRef.update(user);
      return resolve();
      // console.log(
      // 'userKlas succesfully updated with ID: ',
      // userDocRef.id,
      // ' and ',
      // teacherKlasRef.id
      // )
    });
  }

  // async getStudentKlassenOverview(
  //   student: User
  // ): Promise<Observable<StudentKlassenOverview>> {
  //   return new Promise((resolve, reject) => {
  //     // console.log(student);
  //     const studentKlassenOverviewRef = this.firestore.doc(
  //       'users/' +
  //         student.uid +
  //         '/studentKlassenOverview/' +
  //         student.studentKlassenOverviewId
  //     );
  //     let studentKlassenOverview$ = studentKlassenOverviewRef.valueChanges();
  //     return resolve(studentKlassenOverview$);
  //   });
  // }

  async getStudentKlassen(student: User): Promise<Observable<Klas[]>> {
    return new Promise((resolve, reject) => {
      const tempStudent: User = {
        uid: student.uid,
        firstName: student.firstName,
        lastName: student.lastName,
        email: student.email,
      };
      this.klassenCollection = this.firestore.collection('klassen', (ref) =>
        ref.where('klasStudenten', 'array-contains', tempStudent)
      );
      let klassen$ = this.klassenCollection.snapshotChanges().pipe(
        map((snaps) => {
          return snaps.map((snap) => {
            const data = snap.payload.doc.data() as Klas;
            return data;
          });
        })
      );
      return resolve(klassen$);
    });
  }

  async getUserOrdersCount(user: User) {
    const ordersCollection = collection(this.db, 'orders');
    const queryCollection = query(
      ordersCollection,
      where('email', '==', user.email)
    );
    const snapShot = await getCountFromServer(queryCollection)
    return snapShot.data().count;
  }

  async getFirstUserOrders(user: User, batchLength: number): Promise<Observable<Order[]>> {
    const ordersCollection = this.firestore.collection('orders', (ref) =>
      ref.where('email', '==', user.email).orderBy('orderDate', 'desc').limit(batchLength)
    );
    const orders$ = ordersCollection.snapshotChanges().pipe(
      map((snaps) => {
        return snaps.map((snap) => {
          const data = snap.payload.doc.data() as Order;
          return data;
        });
      })
    );
    return orders$;
  }

  async getNextUserOrders(
    user: User,
    order: Order, batchLength: number
  ): Promise<Observable<Order[]>> {
    const ordersCollection = this.firestore.collection('orders', (ref) =>
      ref
        .where('email', '==', user.email)
        .orderBy('orderDate', 'desc')
        .startAfter(order.orderDate)
        .limit(batchLength)
    );
    const orders$ = ordersCollection.snapshotChanges().pipe(
      map((snaps) => {
        return snaps.map((snap) => {
          const data = snap.payload.doc.data() as Order;
          return data;
        });
      })
    );
    return orders$;
  }

  async getPreviousUserOrders(
    user: User,
    order: Order,
    batchLength: number
  ): Promise<Observable<Order[]>> {
    const ordersCollection = this.firestore.collection('orders', (ref) =>
      ref
        .where('email', '==', user.email)
        .orderBy('orderDate', 'desc')
        .endBefore(order.orderDate)
        .limitToLast(batchLength)
    );
    const orders$ = ordersCollection.snapshotChanges().pipe(
      map((snaps) => {
        return snaps.map((snap) => {
          const data = snap.payload.doc.data() as Order;
          return data;
        });
      })
    );
    return orders$;
  }
}
