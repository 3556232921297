import { RouterCheckService } from './core/shared/services/router-check.service';
import { AuthService } from 'src/app/core/shared/services/auth.service';
import { AuthGuard } from './auth.guard';
import { Component, OnInit } from '@angular/core';
import { GuardsCheckStart, GuardsCheckEnd, Router } from '@angular/router';
import { NbIconLibraries } from '@nebular/theme';
import { ScriptService } from './core/shared/services/script.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Pienter';
  loading: boolean;
  test: boolean = false;
  // equation: string = `$$ c = \\pm\\sqrt{a^2 + b^2} $$ Examples`;
  isScriptLoaded = false;
  timespan: string;

  constructor(
    private iconLibraries: NbIconLibraries,
    private router: Router,
    private authGuard: AuthGuard,
    private authService: AuthService,
    private routerCheckService: RouterCheckService,
    private scriptService: ScriptService
  ) {

    this.iconLibraries.registerSvgPack('custom_1', {
      'myicon': '<img src="../assets/images/alan.png" width="25px">',
      'bookshelf': '<img src="../assets/css/svg/003-bookshelf.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'settings': '<img src="../assets/css/svg/wrench.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'settings_2': '<img src="../assets/css/svg/005-settings-1.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'help': '<img src="../assets/css/svg/question_hand.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'info_1': '<img src="../assets/css/svg/008-info.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'info_2': '<img src="../assets/css/svg/006-lifebuoy.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'dashboard_1': '<img src="../assets/css/svg/002-home.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'feedback-log-1': '<img src="../assets/css/svg/feedback-log-1.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'dashboard_2': '<img src="../assets/css/svg/001-speedometer.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'home_1': '<img src="../assets/css/svg/home.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'subscription_1': '<img src="../assets/css/svg/subscription-1.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'admin': '<img src="../assets/css/svg/admin.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'admin_profile': '<img src="../assets/css/svg/admin.svg" width="54px" height="54px" viewBox="0 0 24 24">',
      'group': '<img src="../assets/css/svg/group_1.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'user_interface': '<img src="../assets/css/svg/user.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'book_plus': '<img src="../assets/css/svg/book_a1.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'correct_1': '<img src="../assets/css/svg/008-checked-1.svg" width="100%" height="100%"  viewBox="0 0 24 24">',
      'incorrect_1': '<img src="../assets/css/svg/005-cancel.svg" width="100%" height="100%"  viewBox="0 0 24 24">',
      'open_1': '<img src="../assets/css/svg/001-question-1.svg"  width="32px" height="32px" viewBox="0 0 24 24">',
      'question_1': '<img src="../assets/css/svg/001-question-2.svg"  width="32px" height="32px" viewBox="0 0 24 24">',
      'finished_1': '<img src="../assets/css/svg/008-checked-2.svg"  width="32px" height="32px" viewBox="0 0 24 24">',
      'star_yellow': '<img src="../assets/css/svg/star_yellow.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'star_blue': '<img src="../assets/css/svg/star_blue.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'star_red': '<img src="../assets/css/svg/star_red.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'star_green': '<img src="../assets/css/svg/star_green.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'star_grey': '<img src="../assets/css/svg/star_grey.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'user_profile': '<img src="../assets/css/svg/user_profile.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'success': '<img src="../assets/css/svg/success.svg" width="54px" height="54px" viewBox="0 0 60 60">',
      'arrow_left_blue': '<img src="../assets/css/svg/arrow_left_blue.svg" width="54px" height="54px" viewBox="0 0 60 60">',
      'arrow_right_blue': '<img src="../assets/css/svg/arrow_right_blue.svg" width="54px" height="54px" viewBox="0 0 60 60">',
      'books_mouse': '<img src="../assets/css/svg/books_mouse.svg" width="54px" height="54px" viewBox="0 0 60 60">',
      'elearning': '<img src="../assets/css/svg/elearning.svg" width="54px" height="54px" viewBox="0 0 60 60">',
      'student_1': '<img src="../assets/css/svg/student_1.svg" width="54px" height="54px" viewBox="0 0 60 60">',
      'student_2': '<img src="../assets/css/svg/student_1.svg" width="100%" height="100%" viewBox="0 0 24 24">',
      'certification': '<img src="../assets/css/svg/student_1.svg" width="54px" height="54px" viewBox="0 0 60 60">',
      'maximize': '<img src="../assets/css/svg/maximize.svg" width="100%" height="100%" viewBox="0 0 12 12">',
      'minimize': '<img src="../assets/css/svg/minimize.svg" width="100%" height="100%" viewBox="0 0 12 12">',
      'close_button': '<img src="../assets/css/svg/close-button.svg" width="100%" height="100%" viewBox="0 0 12 12">',
      'open_book': '<img src="../assets/css/svg/open_book.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'arrow_right': '<img src="../assets/css/svg/arrow-right-2-svgrepo-com.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'arrow_left': '<img src="../assets/css/svg/arrow-left-2-svgrepo-com.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'grid_layout': '<img src="../assets/css/svg/grid-view-1.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'list_layout': '<img src="../assets/css/svg/list-view-1.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'menu-arrow': '<img src="../assets/css/svg/menu-arrow-outline.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'menu-outline': '<img src="../assets/css/svg/menu-outline.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'professor1': '<img src="../assets/css/svg/professor.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'admin_skull': '<img src="../assets/css/svg/admin_skull.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'book_apple': '<img src="../assets/css/svg/book_apple.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'menu-outline-bootstrap': '<img src="../assets/css/svg/menu-outline-bootstrap.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'grid-outline-bootstrap': '<img src="../assets/css/svg/grid-outline-bootstrap.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'undraw_social': '<img src="../assets/css/svg/undraw_social_update_re_kdu4.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'blue-circle': '<img src="../assets/css/svg/blue-circle.svg" width="75%" height="75%" viewBox="0 0 60 60">',
      'circle-blue-outline': '<img src="../assets/css/svg/circle-blue-outline.svg" width="75%" height="75%" viewBox="0 0 60 60">',
      'pienter_logo': '<img src="../assets/css/svg/pienter_logo_header.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'pienter_profile_icon_round': '<img src="../assets/css/svg/pienter_profile_icon_round.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'admin_captain': '<img src="../assets/css/svg/admin_captain.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'modify_roles_1': '<img src="../assets/css/svg/modify_roles_1.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'process_payments': '<img src="../assets/css/svg/process_payments.svg" width="100%" height="100%" viewBox="0 0 60 60">',
      'leaderboard1': '<img src="../assets/css/svg/leaderboard.png" width="100%" height="100%" viewBox="0 0 60 60">',
      'group1': '<img src="../assets/css/svg/group.svg" width="100%" height="100%" viewBox="0 0 60 60">',

    });

  }

async  ngOnInit() {
    // this.isScriptLoaded = false;
    // await this.scriptService.loadScript1();
    // await this.scriptService.loadScriptPoly();
    // await this.scriptService.loadScriptMathJax();
    // await this.scriptService.loadScriptKatex1();
    // await this.scriptService.loadScriptKatex2();
    // const countdown = (window as any).countdown;
    // this.timespan = countdown(new Date(2000, 0, 1)).toString();
    // this.isScriptLoaded = true;

    this.routerCheckService.setCurrentRoute(this.router.url);
    this.router.events.subscribe(el => this.routerCheckService.setCurrentRoute(this.router.url));
    // this.authService.checkLogin();


  }



}
