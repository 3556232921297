import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { map, Observable, take, tap } from 'rxjs';
import { AuthService } from './core/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TeacherGuard implements CanLoad {
  constructor(private auth: AuthService, private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.auth.customClaims$.pipe(
      take(1),
      map((claims: any) =>
        claims &&
        // ((claims.teacher == true && claims.pilotAth) ||
        (claims.teacher == true ||
          claims.admin == true ||
          claims.superAdmin == true)
          ? true
          : false
      ),
      tap((gotRoles) => {
        if (!gotRoles) {
          // console.log('access denied');
          // alert('You are not logged in with the proper credentials!');
          this.router.navigateByUrl('/public-interface/404');
          return false;
        } else {
          // console.log('acces granted');
          return true;
        }
      })
    );
  }
}
