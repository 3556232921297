import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { map, Observable, take, tap } from 'rxjs';
import { AuthService } from './core/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EmailVerifiedGuard implements CanLoad {
  constructor(private auth: AuthService, private router: Router) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.auth.customClaims$.pipe(
      take(1),
      map(
        (claims: any) =>
          claims.email_verified == true ||
          claims.superAdmin == true ||
          claims.admin == true ||
          claims.contentWriter == true
      ),
      tap((emailVerified) => {
        if (!emailVerified) {
          // console.log('access denied');
          this.auth.signOut();
          alert('Email nog niet geverifieerd. Doe dit aub eerst');
          this.router.navigateByUrl('/public-interface/login');
          return false;
        } else {
          // console.log('acces granted');
          return true;
        }
      })
    );
  }
}
