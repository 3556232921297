import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { User } from '../models/user.model';
import firebase from 'firebase/compat/app';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DeviceDetectorService } from 'ngx-device-detector';
import dayjs from 'dayjs';
import { PaymentMethod } from '../models/order.model';
import { GiftCardService } from './admin-services/giftCard.service';

@Injectable()
// { providedIn: 'root' }
export class AuthService {
  // authState: FirebaseAuthState = null
  public user: User;
  public userIsLoggedIn: boolean;
  user$: Observable<User>;
  currentUser$: ReplaySubject<User> = new ReplaySubject<User>(1);
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  customClaims: any;
  db = firebase.firestore();

  constructor(
    private firestore: AngularFirestore,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private fns: AngularFireFunctions,
    private deviceService: DeviceDetectorService,
    private giftCardService: GiftCardService
  ) {}

  get auth$(): Observable<User> {
    return this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          const userInfo$ = this.afs
            .doc<User>(`users/${user.uid}`)
            .valueChanges();
          return userInfo$;
        } else {
          return of(null);
        }
      })
    );
  }

  get customClaims$() {
    return this.afAuth.idTokenResult.pipe(
      switchMap((tokens) => {
        if (tokens) {
          if (tokens.claims) {
            return of(tokens.claims);
          } else {
            return of(null);
          }
        }
        return of(null);
      })
    );
  }

  authenticated() {
    return this.afAuth.authState !== null;
  }

  async signup(user: User, giftCardCode?: string) {
    const batch = this.db.batch();
    try {
      const newUserCredential: firebase.auth.UserCredential =
        await this.afAuth.createUserWithEmailAndPassword(
          user.email,
          user.password
        );
      delete user.password;
      const message = await firstValueFrom(
        this.fns.httpsCallable('createUserInDatabase')({
          user: user,
          uid: newUserCredential.user.uid,
          giftCardCode,
        })
      );
      this.afAuth.signOut();
      // console.log('Signup successfull! for ', newUserCredential);
      const emailSend = await newUserCredential.user.sendEmailVerification();
      // console.log(emailSend);
      return newUserCredential;
    } catch (error) {
      console.log(error);
    }
  }

  async redirectBasedOnClaims() {
    this.customClaims = await firstValueFrom(this.customClaims$);
    // // console.log(this.customClaims);
    if (this.customClaims) {
      if (this.customClaims.superAdmin) {
        this.router.navigateByUrl('/super-admin-interface/home');
      } else if (this.customClaims.admin) {
        this.router.navigateByUrl('/admin-interface/dashboard');
      } else if (this.customClaims.contentCreator) {
        this.router.navigateByUrl('/content-creator-interface/home');
      } else if (this.customClaims.teacher) {
        // this.router.navigateByUrl('/teacher-interface/dashboard/students');
        this.router.navigateByUrl('/student-interface/home');
      } else if (this.customClaims.student) {
        this.router.navigateByUrl('/student-interface/home');
      }
    }
  }

  isAuthenticated(): Observable<User> {
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          const userInfo$ = this.afs
            .doc<User>(`users/${user.uid}`)
            .valueChanges();
          return userInfo$;
        } else {
          this.userIsLoggedIn = false;
        }
      })
    );
    return this.user$;
  }

  checkLogin() {
    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        this.userIsLoggedIn = true;
        return true;
      } else {
        return false;
      }
    });
  }

  async addDeviceInfoToLogin(user: User) {
    let loginInfoRef;
    if (user.loginInfoId) {
      loginInfoRef = this.db.doc(
        'users/' + user.uid + '/loginInfo/' + user.loginInfoId
      );
    } else {
      loginInfoRef = this.db
        .collection('users/' + user.uid + '/loginInfo/')
        .doc();
      const userRef = this.db.doc('users/' + user.uid);
      try {
        await userRef.update({ loginInfoId: loginInfoRef.id });
      } catch (error) {
        console.log(error);
      }
    }
    const deviceInfo = this.deviceService.getDeviceInfo();
    try {
      const message = await loginInfoRef.set(
        {
          loggedIn: true,
          deviceInfo: deviceInfo,
          lastLoginTime: dayjs().unix(),
        },
        { merge: true }
      );
    } catch (error) {}
  }

  async getLoginInfoObs(user: User) {
    // console.log(user);
    const loginInfoDoc = this.firestore.doc(
      'users/' + user.uid + '/loginInfo/' + user.loginInfoId
    );
    const loginInfoSnap$ = loginInfoDoc.snapshotChanges().pipe(
      map((snaps) => {
        return snaps.payload.data();
      })
    );
    return loginInfoSnap$;
  }

  async emailLogin(email: string, password: string): Promise<any> {
    const test = this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(async (value) => {
        //succesfully logged in. Now Checking for at least credentials
        this.customClaims = await firstValueFrom(this.customClaims$);
        // console.log(this.customClaims);
        if (
          this.customClaims.superAdmin ||
          this.customClaims.contentWriter ||
          this.customClaims.admin ||
          (this.customClaims.email_verified &&
            (this.customClaims.student || this.customClaims.teacher))
        ) {
          return this.customClaims;
          // } else if (
          //   !this.customClaims.pilotAth &&
          //   !this.customClaims.contentWriter &&
          //   !this.customClaims.admin &&
          //   !this.customClaims.superAdmin
          // ) {
          //   this.afAuth.signOut();
          //   return 'notAuthorizedForPilot';
        } else if (
          !this.customClaims.email_verified &&
          !value.user.emailVerified
        ) {
          this.afAuth.signOut();
          return 'emailNotVerified';
        } else {
          this.afAuth.signOut();
        }
        // return this.customClaims;

        // if (
        //   this.customClaims.superAdmin ||
        //   this.customClaims.contentWriter ||
        //   this.customClaims.admin
        // ) {
        //   return this.customClaims;
        // }
        // if (
        //   !this.customClaims.pilotAth &&
        //   !this.customClaims.contentWriter &&
        //   !this.customClaims.admin &&
        //   !this.customClaims.superAdmin
        // ) {
        //   this.afAuth.signOut();
        //   return 'notAuthorizedForPilot';
        // } else if (
        //   (this.customClaims.pilotAth &&
        //     !this.customClaims.email_verified &&
        //     !this.customClaims.contentWriter &&
        //     !this.customClaims.admin &&
        //     !this.customClaims.superAdmin) ||
        //   !value.user.emailVerified
        // ) {
        //   this.afAuth.signOut();
        //   return 'emailNotVerified';
        // }
        // return this.customClaims;
      });
    return test;
  }

  async sendEmailVerification() {
    try {
      const user = await firstValueFrom(this.afAuth.user);
      const emailSend = await user.sendEmailVerification();
      return emailSend;
    } catch (error) {
      return error;
    }
  }

  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider);
  }

  async googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const userCredential = await this.oAuthLogin(provider);
      // console.log('Success', userCredential);
      this.setGoogleSignInUser(userCredential);
    } catch (error) {
      console.error('Something went wrong: ', error);
    }
  }

  setGoogleSignInUser(userx: firebase.auth.UserCredential) {
    let user: User = {};
    let uid: string;
    if (userx.additionalUserInfo.isNewUser == true) {
      let googleUser: any;
      googleUser = userx.additionalUserInfo.profile;
      user.email = googleUser.email;
      user.firstName = googleUser.given_name;
      user.lastName = googleUser.family_name;
      uid = userx.user.uid;
    }
  }

  async sendPasswordResetEmail(email: string) {
    await this.afAuth.sendPasswordResetEmail(email);
    // console.log('Password reset email succesfully sent');
  }

  async signOut() {
    try {
      await this.afAuth.signOut();
      // console.log('successfuly logged out!');
      this.router.navigate(['/public-interface/login']);
    } catch (error) {
      console.error('Something went wrong: ', error);
    }
  }
}
