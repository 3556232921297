import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  DocumentData,
  Query,
} from '@angular/fire/compat/firestore';
import {
  Observable,
  map,
  merge,
  debounceTime,
  take,
  ReplaySubject,
} from 'rxjs';
import { Order } from '../../models/order.model';
import { User } from '../../models/user.model';
import * as firebase from 'firebase/compat/app';
import { AbstractControl } from '@angular/forms';
import {
  where,
  getCountFromServer,
  query,
  collection,
} from 'firebase/firestore';
import { GiftCard } from '../../models/affiliate.model';
@Injectable({
  providedIn: 'root',
})
export class GiftCardService {
  db = firebase.default.firestore();
  getOrderId = this.db.collection('orders');
  currentGiftCard$: ReplaySubject<GiftCard> = new ReplaySubject<GiftCard>(1);

  constructor(private firestore: AngularFirestore) {}

  async getGiftCardForPI(giftCardCode?: string): Promise<Observable<any>> {
    let collection: AngularFirestoreCollection<unknown>;
    collection = this.firestore.collection('giftCards', (ref) =>
      ref.where('privateCode', '==', giftCardCode.trim())
    );
    const giftCard$ = collection.snapshotChanges().pipe(
      map((snaps) => {
        return snaps.map((snap) => {
          const data = snap.payload.doc.data() as GiftCard;
          return data;
        });
      }),
      map((el) =>
        el[0]
          ? {
              value: el[0].value,
              cardSubscription: el[0].cardSubscription,
              cardCashed: el[0].cardCashed,
            }
          : undefined
      )
    );
    return giftCard$;
  }

  async addGiftCards(giftCards: GiftCard[]) {
    const batch = this.db.batch();
    giftCards.forEach((giftCard) => {
      const giftCardRef = this.db.collection('giftCards').doc();
      giftCard.id = giftCardRef.id;
      const giftCardParam = JSON.parse(JSON.stringify(giftCard));
      batch.set(giftCardRef, giftCardParam, { merge: false });
    });
    await batch.commit();
  }

  async updateGiftCard(giftCard: GiftCard) {
    const giftCardRef = this.db.doc('giftCards/' + giftCard.id);
    const giftCardParam = JSON.parse(JSON.stringify(giftCard));
    giftCardRef.set(giftCardParam, { merge: false });
  }

  async deleteAllGiftCards() {
    const giftCards = await this.db.collection('giftCards').get();
    giftCards.docs.forEach(async (doc) => {
      await doc.ref.delete();
    });
  }

  async deleteGiftCard(giftCard: GiftCard) {
    const giftCardRef = this.db.doc('giftCards/' + giftCard.id);
    const batch = this.db.batch();
    batch.delete(giftCardRef);
    await batch.commit();
    console.log('Success');
  }

  async getGiftCardsCount(searchField?: string, searchValue?: string) {
    const giftCardsCollection = collection(this.db, 'giftCards');
    let queryCollection: any;
    if (searchField && searchValue) {
      queryCollection = query(
        giftCardsCollection,
        where(searchField, '==', searchValue.trim())
      );
    } else {
      queryCollection = query(giftCardsCollection);
    }
    const snapShot = await getCountFromServer(queryCollection);
    return snapShot.data().count;
  }

  async getFirstGiftCards(
    batchLength: number,
    searchField?: string,
    searchValue?: string
  ): Promise<Observable<GiftCard[]>> {
    let collection: AngularFirestoreCollection<unknown>;
    if (searchField && searchValue) {
      collection = this.firestore.collection('giftCards', (ref) =>
        ref
          .where(searchField, '==', searchValue.trim())
          .orderBy('createdOn', 'desc')
          .orderBy('id', 'desc')
          .limit(batchLength)
      );
    } else {
      collection = this.firestore.collection('giftCards', (ref) =>
        ref
          .orderBy('createdOn', 'desc')
          .orderBy('id', 'desc')
          .limit(batchLength)
      );
    }
    const giftCards$ = collection.snapshotChanges().pipe(
      map((snaps) => {
        return snaps.map((snap) => {
          const data = snap.payload.doc.data() as GiftCard;
          return data;
        });
      })
    );
    return giftCards$;
  }

  async getNextGiftCards(
    giftCard: GiftCard,
    batchLength: number,
    searchField?: string,
    searchValue?: string
  ): Promise<Observable<GiftCard[]>> {
    let collection: AngularFirestoreCollection<unknown>;
    if (searchField && searchValue) {
      collection = this.firestore.collection('giftCards', (ref) =>
        ref
          .where(searchField, '==', searchValue.trim())
          .orderBy('createdOn', 'desc')
          .orderBy('id', 'desc')
          .startAfter(giftCard.createdOn, giftCard.id)
          .limit(batchLength)
      );
    } else {
      collection = this.firestore.collection('giftCards', (ref) =>
        ref
          .orderBy('createdOn', 'desc')
          .orderBy('id', 'desc')
          .startAfter(giftCard.createdOn, giftCard.id)
          .limit(batchLength)
      );
    }
    const giftCards$ = collection.snapshotChanges().pipe(
      map((snaps) => {
        return snaps.map((snap) => {
          const data = snap.payload.doc.data() as GiftCard;
          return data;
        });
      })
    );
    return giftCards$;
  }

  async getPreviousGiftCards(
    giftCard: GiftCard,
    batchLength: number,
    searchField?: string,
    searchValue?: string
  ): Promise<Observable<GiftCard[]>> {
    let collection: AngularFirestoreCollection<unknown>;
    if (searchField && searchValue) {
      collection = this.firestore.collection('giftCards', (ref) =>
        ref
          .where(searchField, '==', searchValue.trim())
          .orderBy('createdOn', 'desc')
          .orderBy('id', 'desc')
          .endBefore(giftCard.createdOn, giftCard.id)
          .limitToLast(batchLength)
      );
    } else {
      collection = this.firestore.collection('giftCards', (ref) =>
        ref
          .orderBy('createdOn', 'desc')
          .orderBy('id', 'desc')
          .endBefore(giftCard.createdOn, giftCard.id)
          .limitToLast(batchLength)
      );
    }
    const giftCards$ = collection.snapshotChanges().pipe(
      map((snaps) => {
        return snaps.map((snap) => {
          const data = snap.payload.doc.data() as GiftCard;
          return data;
        });
      })
    );
    return giftCards$;
  }
}
