import {
  redirectUnauthorizedTo,
  canActivate,
  hasCustomClaim,
} from '@angular/fire/compat/auth-guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { SuperAdminGuard } from './super-admin.guard';
import { AdminGuard } from './admin.guard';
import { ContentCreatorGuard } from './content-creator.guard';
import { StudentGuard } from './student.guard';
import { TeacherGuard } from './teacher.guard';
import { EmailVerifiedGuard } from './email-verified.guard';

// const redirectUnauthorizedToLogin = () =>
//   redirectUnauthorizedTo(['/public-interface/login']);

export const routes: Routes = [
  // { path: '', component: AppComponent},
  { path: '', redirectTo: '/public-interface/home', pathMatch: 'full' },
  {
    path: 'public-interface',
    loadChildren: () =>
      import('./core/public-interface/public-interface.module').then(
        (m) => m.PublicInterfaceModule
      ),
  },
  {
    path: 'content-creator-interface',
    loadChildren: () =>
      import(
        './core/content-creator-interface/content-creator-interface.module'
      ).then((m) => m.AdminInterfaceModule),
    canLoad: [AuthGuard, ContentCreatorGuard],
  },
  {
    path: 'student-interface',
    loadChildren: () =>
      import('./core/student-interface/student-interface.module').then(
        (m) => m.UserInterfaceModule
      ),
    canLoad: [AuthGuard, StudentGuard, EmailVerifiedGuard],
  },
  {
    path: 'teacher-interface',
    loadChildren: () =>
      import('./core/teacher-interface/teacher-interface.module').then(
        (m) => m.TeacherInterfaceModule
      ),
    canLoad: [AuthGuard, TeacherGuard, EmailVerifiedGuard],
  },
  {
    path: 'admin-interface',
    loadChildren: () =>
      import('./core/admin-interface/admin-interface.module').then(
        (m) => m.AdminInterfaceModule
      ),
    // ...canActivate(redirectUnauthorizedToLogin),
    canLoad: [AuthGuard, AdminGuard],
  },
  {
    path: 'super-admin-interface',
    loadChildren: () =>
      import('./core/super-admin-interface/super-admin-interface.module').then(
        (m) => m.SuperAdminInterfaceModule
      ),
    // ...canActivate(redirectUnauthorizedToLogin),
    canLoad: [AuthGuard, SuperAdminGuard],
  },
  { path: '**', redirectTo: '/public-interface' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // scrollPositionRestoration: 'enabled',
      // relativeLinkResolution: 'legacy',
      // anchorScrolling: 'enabled',
      // scrollOffset: [0, 90],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
