import { RouterCheckService } from './core/shared/services/router-check.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  NbInputModule,
  NbSidebarModule,
  NbThemeModule,
  NbMenuModule,
  NbActionsModule,
  NbAccordionModule,
  NbDialogModule,
  NbDatepickerModule,
  NbToastrModule,
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InViewportModule } from 'ng-in-viewport';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
// import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthService } from './core/shared/services/auth.service';
import { UserService } from './core/shared/services/user-services/user.service';
import { AuthGuard } from './auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PipesModule } from './core/shared/pipes/pipes.module';
import { SafeHtmlPipe } from './core/shared/pipes/safe-html.pipe';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  AngularFireFunctionsModule,
  // USE_EMULATOR,
} from '@angular/fire/compat/functions';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
// import { USE_EMULATOR as USE_PUBSUB_EMULATOR } from '@angular/fire/compat/pubsub';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { SuperAdminGuard } from './super-admin.guard';
import { ContentCreatorGuard } from './content-creator.guard';
import { ScriptService } from './core/shared/services/script.service';
import { MathText1Directive } from './math-text-1.directive';
import { AdminGuard } from './admin.guard';

@NgModule({
  declarations: [
    AppComponent,
    MathText1Directive,
    // SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NbInputModule,
    NbThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    InViewportModule,
    // NgbModule, // NbSidebarModule.forRoot(), //if this is your app.module
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    // AngularFirestore,
    AngularFireAuthModule,
    NbMenuModule.forRoot(),
    // NbDialogService,
    NbActionsModule,
    NbAccordionModule,
    BrowserAnimationsModule,
    NbDialogModule.forRoot(),
    PipesModule,
    AppRoutingModule,
    NbDatepickerModule.forRoot(),
    NbToastrModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
    // RecaptchaModule,
    // RecaptchaFormsModule,
    // KatexModule
    // MathjaxModule.forRoot(/*Optional Config*/)
    // CKEditorModule
  ],
  providers: [
    AuthService,
    UserService,
    AuthGuard,
    RouterCheckService,
    SuperAdminGuard,
    ContentCreatorGuard,
    ScriptService,
    AdminGuard,
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     siteKey: environment.recaptcha.siteKey,
    //   } as RecaptchaSettings,
    // },
    // {
    //   provide: USE_AUTH_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 9099] : undefined,
    // },
    // {
    //   provide: USE_DATABASE_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 9000] : undefined,
    // },
    // {
    //   provide: USE_PUBSUB_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 8080] : undefined,
    // }
    // {
    //   provide: USE_FIRESTORE_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 8080] : undefined,
    // },
    // {
    //   provide: USE_FUNCTIONS_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 5001] : undefined,
    // },
  ],
  // schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
