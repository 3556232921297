import { Pipe, PipeTransform } from '@angular/core';
import { Onderwerp } from '../models/onderwerp.model';

@Pipe({
  name: 'filterParagraaf'
})
export class FilterParagraafPipe implements PipeTransform {

  //   transform(items: any[], callback: (item: any) => boolean): any {
  //     if (!items || !callback) {
  //         return items;
  //     }
  //     return items.filter(item => callback(item));
  // }

  transform(onderwerpen: Onderwerp[], paragraafId: string) {
    // // console.log(onderwerpen);
    // // console.log(paragraafId);
    const filteredItems = onderwerpen.filter((v, i, s) => {
      return v.paragraafId == paragraafId
    })
    // // console.log(filteredItems);
    return filteredItems;
  }

}
