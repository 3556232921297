import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { map, Observable, take, tap } from 'rxjs';
import { AuthService } from './core/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminGuard implements CanLoad {
  constructor(private auth: AuthService, private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.auth.customClaims$.pipe(
      take(1),
      map((claims: any) => Boolean(claims.superAdmin)),
      tap((isSuperAdmin) => {
        if (!isSuperAdmin) {
          // console.log('user not SuperAdmin');
          // alert('You are not logged in with the proper credentials!');
          this.router.navigateByUrl('/public-interface/404');
          return false;
        } else {
          // console.log('acces granted for SuperAdmins only');
          return true;
        }
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class SuperAdminGuardActivate implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.auth.customClaims$.pipe(
      take(1),
      map((claims: any) => {
        if (claims) {
          return Boolean(claims.superAdmin);
        } else {
          // console.log('user not SuperAdmin');
          // alert('You are not logged in with the proper credentials!');
          this.router.navigateByUrl('/public-interface/404');
          return false;
        }
      }),

      tap((isSuperAdmin) => {
        if (!isSuperAdmin) {
          // console.log('user not SuperAdmin');
          // alert('You are not logged in with the proper credentials!');
          this.router.navigateByUrl('/public-interface/404');
          return false;
        } else {
          // console.log('acces granted for SuperAdmins only');
          return true;
        }
      })
    );
  }
}
